<template>
  <div class="main">
    <div class="bg_dark"></div>
    <div class="modal">
      <div class="modal-left">
        <img
          width="100%"
          height="100%"
          src="@/assets/img/bonus-reg-banner_ru.jpg"
        />
      </div>
      <div class="modal-right">
        <div class="modal-right-content">
          <p class="title">Регистрация</p>
          <form
            action="#"
            id="registrationForm"
            :class="{'form_disabled': isRequestSending}"
            @submit.prevent="submitForm"
          >
            <div class="form-group block-number">
              <label for="phone">Телефон</label><br />
              <div>
                <select name="telCode" id="telCode" v-model="telCode">
                  <option value="+7" selected>+7</option>
                  <option value="+380">+380</option>
                </select>
                <input type="tel" id="phone" placeholder="Телефон" required v-model="phone" />
              </div>
            </div>
            <div class="form-group">
              <label for="password">Пароль</label><br />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Введите пароль"
                required
                v-model="password"
              />
            </div>
            <div class="form-row">
              <div>
                <label for="currency">Валюта</label>
                <select id="currency" name="currency" v-model="currency">
                  <option value="RUB" selected>RUB</option>
                  <option value="UAH">UAH</option>
                </select>
              </div>
              <div class="promocodeBlock">
                <label for="currency">БОНУС код</label>
                <input
                  type="text"
                  placeholder="Введите здесь"
                  id="promocode"
                  v-model="promocode"
                />
              </div>
            </div>
            <div class="checkbox_cont">
              <div class="checkbox-goup">
                <input type="checkbox" checked required id="checkAction" />
                <p>Хочу узнавать о новых событиях и акциях через рассылки</p>
              </div>
              <div class="checkbox-goup">
                <input
                  type="checkbox"
                  checked
                  required
                  name="check"
                  id="check"
                />
                <p>
                  Я подтверждаю, что мне исполнилось 18 лет, и я ознакомился с
                  <a href="#">Правилами и условиями использования</a> сайта, и
                  принимаю <a href="#">Политику конфиденциальности</a>
                </p>
              </div>
            </div>
            <input type="submit" class="btn-register" id="registr" value="Начать игру" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'OPA',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
